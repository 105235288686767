@font-face{font-family:'cocon-next-arabic';src: url('https://www.fontstatic.com/fonts/cocon-next-arabic/cocon-next-arabic.eot?#iefix');src: local('كوكن'), local('cocon-next-arabic'),url('https://www.fontstatic.com/fonts/cocon-next-arabic/cocon-next-arabic.woff') format('woff');}
.App {
  text-align: center;
  font-family: 'cocon-next-arabic';
}
*{
  box-sizing: border-box;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/*Support Arabic section*/
/* Custom RTL styles */
.navbar-nav {
  direction: rtl;
}

.navbar-nav .nav-link {
  padding-right: 20px;
  padding-left: 0;
}

.navbar-toggler {
  order: -1; /* Push toggler to the left */
}

/* Change toggler icon direction */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Change toggler icon alignment */
.navbar-toggler {
  margin-right: 15px;
}
.add-icon,.delete-icon{
  cursor: pointer;
}

.add-icon:hover{
  color:lightgreen;
}
.delete-icon:hover{
  color:lightcoral;
}

td, th{
  border:2px solid black;
}

th{
  background-color: rgb(6, 74, 86);
  color:white;
}

.teacher-activity{
  background-color: #3e585f;
  cursor:pointer;
}
.teacher-activity:hover{
  background-color: #3a8aa0;
}
.share-icon{
  cursor: pointer;
}
.share-icon:hover{
  color: #3e585f;
}

.quiz-thumbnail{
  cursor:pointer;
}
.quiz-thumbnail-container:hover .quiz-thumbnail{
  background-color:rgb(0,0,0,0.1);
}

.quiz-thumbnail-container{

  max-width: 375px;
  margin:20px;
}
.paper-class{

  height:fit-content;
  min-height: 60vh;
}
.footer-link{
  color:inherit;
  color:rgb(19, 167, 246);
  text-decoration: none;
}
.footer-link:hover{
  text-decoration: underline;
}

.like-icon{
  color:rgba(69, 69, 69, 0.737);
  cursor: pointer;
}
.fav-icon{
  cursor: pointer;
}
.home-page{
  
background: rgb(198,245,255);
background: linear-gradient(180deg, rgba(198,245,255,1) 0%, rgba(173,220,255,0.3757878151260504) 34%, rgba(0,212,255,0) 100%);
}

.search-filter-option{
  cursor: pointer;
  margin:5px;
  background-color: rgba(0,0,0,0.1);

}

.active-filter{
  background-color: rgba(147, 200, 100, 0.693);
}



@media screen and (max-width: 550px) {
  .quiz-thumbnail-container{
   
    margin:auto;
    margin-top:20px;
    max-width: 300px;
  }
  
}